import React from 'react';

// Components
import { Box } from '@chakra-ui/layout';
import { IconButton, useDisclosure } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import Drawer from '../../molecules/drawer';
import { Link } from 'gatsby';

// Utils
import colors from '../../../utils/colors';

const NavDrawer = ({ items } = {}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <Box
      sx={{
        '@media screen and (min-width: 641px)': {
          // When the screen width is greater than 640px, hide the drawer
          display: 'none',
        },
      }}
    >
      <IconButton
        aria-label="Open navigation drawer"
        ref={btnRef}
        backgroundColor={colors.secondary}
        onClick={onOpen}
        icon={<HamburgerIcon color={colors.primary} />}
      />
      <Drawer
        header="Links"
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {items.map(
            (
              item // Kills the margin on nav items, spaces them out in the drawer
            ) => {
              const key = `${item.props.to}_key`;
              return (
                <Box sx={{ marginBottom: 2 }} key={key}>
                  {React.cloneElement(item, {
                    sx: { margin: 0 },
                    onClick: onClose,
                  })}
                </Box>
              );
            }
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

const NavLinks = ({ items } = {}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '@media screen and (max-width: 640px)': {
          // When the screen width exceeds 640px, hide the nav links
          display: 'none',
        },
      }}
    >
      {items}
    </Box>
  );
};

/**
 * The NavBar component will display a drawer or nav links depending on the screen size.
 * Must be used with the NavLink component.
 */
const NavBar = ({ brand = null, children } = {}) => {
  // Unfortunately the NavLinks and NavDrawer have to be in the DOM.
  // The reason for this is it allows CSS to handle displaying / hiding, which is required for a static website.
  return (
    <Box
      sx={{
        backgroundColor: colors.primary,
        textColor: colors.secondary,
        shadow: '0 0 4px 4px rgba(0, 0, 0, .125)',
        width: '100vw',
        position: 'fixed',
        zIndex: 1,
        top: 0,
      }}
    >
      <Box sx={{ padding: 4 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Link to="/">{brand}</Link>
          <NavLinks items={children} />
          <NavDrawer items={children} />
        </Box>
      </Box>
    </Box>
  );
};

export default NavBar;

import React from 'react';

// Components
import { Link as ChakraLink } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

// Utils
import fonts from '../../../utils/fonts';

const NavItem = ({
  to = null,
  location = '',
  href = null,
  target = null,
  rel = null,
  onClick = () => {},
  children,
  sx = {},
} = {}) => {
  const active = location === to;

  // If we are navigating within the app, use the Gatsby component. Otherwise use the Chakra component.
  // The Gatsby component is special, it prefetches pages for the user and can only be used for app navigation.
  const Link = to ? GatsbyLink : ChakraLink;

  // onClick is only present when used with the nav drawer
  return (
    <Link to={to} href={href} target={target} rel={rel} onClick={onClick}>
      <Text
        as="span"
        sx={{
          margin: 2,
          textDecoration: active ? 'underline' : 'none', // Display underline under text if the item is active
          fontSize: fonts.md,
          transition: 'filter 100ms',
          ':hover': {
            textDecoration: 'underline',
          },
          ...sx,
        }}
      >
        {children}
      </Text>
    </Link>
  );
};

export default NavItem;

import React from 'react';

// Components
import {
  IconButton,
  Drawer as DrawerContainer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

// Utils
import colors from '../../../utils/colors';
import fonts from '../../../utils/fonts';

const Drawer = ({
  header,
  footer,
  children,
  isOpen,
  onClose,
  finalFocusRef,
}) => {
  return (
    <DrawerContainer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={finalFocusRef} // Brings focus back to the button that activated the drawer
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerHeader
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: colors.primary,
              color: colors.secondary,
              fontSize: fonts.lg,
              shadow: '0 0 4px 4px rgba(0, 0, 0, .125)',
            }}
          >
            {header}
            <IconButton
              aria-label="Close drawer"
              sx={{
                backgroundColor: colors.secondary,
              }}
              onClick={onClose}
              icon={<CloseIcon color={colors.primary} />}
            />
          </DrawerHeader>
          <DrawerBody>{children}</DrawerBody>
          <DrawerFooter>{footer}</DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </DrawerContainer>
  );
};

export default Drawer;

import React from 'react';

// Components
import { Box, Heading } from '@chakra-ui/react';
import NavBar from '../nav-bar';
import NavItem from '../../molecules/nav-item';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';

/**
 * The 'Layout' component is a wrapper used for each page. The component includes:
 * - The footer
 * - Navigation
 * - Global styling
 */
const Layout = ({ children, uri } = {}) => {
  // Me
  const name = 'Maxton Delaney';
  const jobTitle = 'Sr. Software Engineer';

  const paths = {
    '/': {
      title: `${name}: ${jobTitle}`,
      description: `Looking for a Fullstack ${jobTitle}? Look no further! I have experience with React, AWS cloud infrastructure, and more!`,
    },
    '/projects': {
      title: `${name}: Projects`,
      description: 'Personal Projects',
    },
    '/404': {
      title: "Uh oh! You're lost!",
      description: 'Just another 404 page.',
    },
  };

  // Meta for the page, defaults to 404
  const meta = paths[uri] || paths['/404'];

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <NavBar
        brand={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <StaticImage
              src="../../../images/me.png"
              alt={name}
              placeholder="blurred"
              style={{
                WebkitMaskImage: '-webkit-radial-gradient(white, black)', // Required for the border radius to work on Safari
                borderRadius: '32px',
                boxShadow: '0 4px 6px 4px rgba(0, 0, 0, .125)',
                maxWidth: '64px',
                maxHeight: '64px',
                marginRight: '16px',
              }}
            />
            <Box>
              <Heading size="lg" as="h1">
                {name}
              </Heading>
              {jobTitle}
            </Box>
          </Box>
        }
      >
        <NavItem to="/" location={uri}>
          Home
        </NavItem>
        <NavItem to="/projects" location={uri}>
          Projects
        </NavItem>
        <NavItem
          href="https://drive.google.com/file/d/1cAx6I1RNRwENkhyjA2td0cwzhbWZS12S/view"
          target="_blank"
          rel="noopener"
        >
          Resume
        </NavItem>
        <NavItem href="mailto:maxtondelaney@gmail.com">Contact</NavItem>
      </NavBar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 24,
          marginBottom: 8,
          padding: 4,
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default Layout;
